// true center
.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

// rows
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-center {
  @extend .flex-row;
  align-items: center;
}

// columns
.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-column-justify-center {
  @extend .flex-column;
  justify-content: center;
}

.flex-column-justify-bottom {
  @extend .flex-column;
  justify-content: flex-end;
}

.flex-column-align-center {
  @extend .flex-column;
  align-items: center;
}
