.hover-grow {
  transition: all .2s ease-in-out;
}

.hover-grow-tiny {
  @extend .hover-grow;

  &:hover {
    transform: scale(1.01);
  }
}

.hover-grow-small {
  @extend .hover-grow;

  &:hover {
    transform: scale(1.025);
  }
}

.hover-grow-medium {
  @extend .hover-grow;

  &:hover {
    transform: scale(1.05);
  }
}

.hover-grow-medium {
  @extend .hover-grow;

  &:hover {
    transform: scale(1.075);
  }
}

.hover-grow-large {
  @extend .hover-grow;

  &:hover {
    transform: scale(1.1);
  }
}
