@import "../../bootstrap-override";

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.45;
}

.carousel-image-full {
  @media only screen and (max-width: get-breakpoints("sm") - 1) {
    display: none;
  }
}

.carousel-image-mobile {
  @media only screen and (min-width: get-breakpoints("sm")) {
    display: none;
  }
}

/* Black background combined with low image opacity for dark effect */
.carousel-image-bg-helper {
  background-color: black;
  width: 100%;
  height: 100%;
  position: absolute;
}

/* Container for putting content in the middle of the carousel items */
.carousel-content-control {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.carousel-content-inner {
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

/* Overwrite width of the left and right controls */
.carousel-control-next, .carousel-control-prev {
  width: 4% !important;
  margin: 10px;

  &:hover {
    cursor: pointer;
  }
}

.carousel-item {
  height: 100vh;
}

.carousel-caption {
  margin-bottom: 7vh;
}

.carousel-indicators {
  margin-bottom: 9vh;
}

.carousel-caption-custom {
  display: initial !important;
}

/* Make the indicators at the bottom into circles */
.carousel-indicators li {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
  margin-left: 6px !important;
  margin-right: 6px !important;
}

/* Nice Button */
.carousel-cta-button {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #f7f7f7;
  flex: 1 1 auto;
  margin: 10px;
  padding: 10px 15px;
  border: 1px solid #f7f7f7;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  transition: .3s;
  &:after {
    position: absolute;
    transition: 0.5s;
    content: '';
    width: 0;
    bottom: 0;
    background: #f7f7f7;
    height: 120%;
    left: -10%;
    transform: skewX(15deg);
    z-index: -1;
  }
  &:hover {
    cursor: pointer;
    color: black !important;
    &:after {
      color: black !important;
      left: -10%;
      width: 120%;
    }
  }
}
