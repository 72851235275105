// margin top
.m-t-5 {
  margin-top: 5px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-15 {
  margin-top: 15px;
}

.m-t-20 {
  margin-top: 20px;
}

// margin-bottom
.m-b-5 {
  margin-bottom: 5px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-b-20 {
  margin-bottom: 20px;
}

// margin vertical
.m-v-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.m-v-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.m-v-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.m-v-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

//padding vertical
.p-v-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.p-v-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.p-v-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.p-v-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
