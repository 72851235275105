.footer-contact-link {
  text-decoration: none !important;
  font-size: 16px;

  @media only screen and (min-width: 992px) {
    font-size: 10pt;
  }
}

.footer-contact-hr {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.contact-link-padding-helper {
  margin-top: 10px;
  margin-bottom: 10px;

  padding-top: 5px;
  padding-bottom: 5px;

  @media screen and (max-width: 991px) {
    padding: 10px;
  }
}
