@import "./variables.scss";

@import "./layout-helpers.scss";
@import "./spacers.scss";
@import "./text-style.scss";

@import "./buttons/modern-button.scss";
@import "./utility/hover-helpers.scss";
@import "./utility/misc.scss";

// Important global stuff

body {
  background-color: white;
  color: $primary-color;
  overflow-x: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}

main {
  flex-grow: 1;
}

main, section {
  position: relative;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $primary-font;
}

h1 {
  font-weight: 600;
  color: #202020;
}

h2 {
  font-weight: 600;
  color: #202020;
}

p, div {
  font-family: $primary-font;
  color: $body-text-color;
}

p {
  font-size: 1.1em;
  line-height: 1.6em;
}

img {
  max-width: 100%;
  object-fit: cover;
  user-select: none;
}

button {
  user-select: none;
}

ul {
  padding-left: 20px;
}

a, a:visited {
  color: inherit;
}
