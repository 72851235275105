@import "./variables.scss";

// colors
.font-primary-color {
  color: $primary-color;
}

.font-primary-highlight {
  color: $primary-highlight-1;
}

.font-secondary-color {
  color: $secondary-color;
}

.font-secondary-highlight {
  color: $secondary-highlight-1;
}

// weights
.font-weight-100 {
  font-weight: 100 !important;
}

.font-weight-200 {
  font-weight: 200 !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}
