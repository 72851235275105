@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap');

$primary-font: "Open Sans", sans-serif;

// From coolors
$color1: #FDFDFD;
$color2: #F9F8F9;
$color3: #F8F7F8;
$color3-highlight: rgb(114, 181, 204);
$color4: #FEF9FF;
$color5: #F9FAFB;
$color5-highlight: rgb(255, 216, 121);
$body-text-color: #615f5f;

// our settings
$primary-color: $color3;
$primary-highlight-1: $color3-highlight;

$secondary-color: $color5;
$secondary-highlight-1: $color5-highlight;

$xs-breakpoint: 575px;
$sm-breakpoint: 767px;
$md-breakpoint: 991px;
$lg-breakpoint: 1199px;
