@import "../../styles/variables.scss";

.header {
  background-color: $color3;
  border-bottom-color: $body-text-color;
  border-width: 0px;
  border-bottom-width: 1px;
  border-style: solid;
}

.header-image {
  height: 46px;
}

.contact-left-item-spacing-helper {
  margin-right: 0px;

  @media only screen and (min-width: 420px) {
    margin-right: 20px;
  }
}

.header-contact-button {
  p {
    color: inherit;
  }

  &:hover {
    color: $color3-highlight;
  }
}
